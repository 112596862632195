import React from "react";
import metamask from "../../assets/img/partner/metamask.svg";
import img01 from "../../assets/img/partner/partner_img01.png";
// import img02 from "../../assets/img/partner/partner_img02.png";
import img03 from "../../assets/img/partner/partner_img03.png";
// import img04 from "../../assets/img/partner/partner_img04.png";
// import img05 from "../../assets/img/partner/partner_img05.png";
// import img06 from "../../assets/img/partner/partner_img06.png";
// import img07 from "../../assets/img/partner/partner_img07.png";
// import img08 from "../../assets/img/partner/partner_img08.png";
// import img09 from "../../assets/img/partner/partner_img09.png";
// import img10 from "../../assets/img/partner/partner_img10.png";

const TopPartners = () => {
  const partners_list = [
    // { src: img01, title: "UniSwap" },
    // { src: img02, title: "Etherscan" },
    { src: img03, title: "PancakeSwap" },
    // { src: img04, title: "" },
    // { src: img05, title: "" },
    // { src: img06, title: "" },
    // { src: img07, title: "" },
    // { src: img08, title: "" },
    // { src: img09, title: "" },
    // { src: img10, title: "" },
  ];

  const wallets_list = [
    { src: metamask, title: "Etherscan" }
 
  ];

  return (
 <div className="partner-area pb-130">
  <div className="container">
  <div className="row justify-content-center">
      <div className="col-lg-5">
        <div className="section-title text-center mb-10">
          <span className="sub-title">Exchanges</span>
        </div>
        <div className="partner-wrap">
          <ul>
            {partners_list.map((item, index) => (
              <li key={index}>
                <img src={item.src} alt={item.title} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="section-title text-center mb-10">
          <span className="sub-title">Wallets</span>
        </div>
        <div className="partner-wrap">
          <ul>
            {wallets_list.map((item, index) => (
              <li key={index}>
                <img src={item.src} alt={item.title} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
  )
};

export default TopPartners;
