import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';

const ContactOneForm = () => {
  const service = process.env.REACT_APP_EMAIL_SERVICE
  const template = process.env.REACT_APP_EMAIL_TEMPLATE
  const key = process.env.REACT_APP_EMAIL_KEY

  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [messageValue, setMessageValue] = useState('');
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(service, template, form.current, key)
      .then((result) => {
          toast.success('The message was successfully sent')
          setNameValue('')
          setEmailValue('')
          setMessageValue('')
          console.log(result.text);
      }, (error) => {
          toast.error('The message was not sent, please try again later');
          console.log(error.text);
      });
  };

  return (
    <div className="contact-form-wrap">
      <form ref={form} onSubmit={sendEmail}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-grp">
              <input id="name" type="text" name="from_name" value={nameValue} onChange={(e) => setNameValue(e.target.value)} placeholder="Enter your Name" required />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-grp">
              <input id='email' type="email" name="from_email" value={emailValue} onChange={(e) => setEmailValue(e.target.value)} placeholder="Enter you email" required />
            </div>
          </div>
        </div>
        <div className="form-grp">
          <textarea id="message" name="message" value={messageValue} onChange={(e) => setMessageValue(e.target.value)} placeholder="Enter your massage" required></textarea>
        </div>
        <div className="submit-btn text-center">
          <button type="submit" className="btn">
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactOneForm;
