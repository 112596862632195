import React from "react";
import Banner from "../../components/Banner/Banner";
import ContactOne from "../../components/Contact/ContactOne";
// import ContactOneInfoItem from "../../components/Contact/ContactOneInfoItem";
import Roadmap from "../../components/Roadmap/Roadmap";
// import Sales from "../../components/Sales/Sales";
// import TeamOne from "../../components/Team/TeamOne";
import TopPartners from "../../components/TopPartners/TopPartners";
// import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import AboutProject from "../../components/AboutProject/AboutProject";

const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">
        <Banner />
       
        <AboutProject />
        <WhoWeAre />
        
        <TopPartners />
        <WhyChooseUs />
        {/* <Sales /> */}

        <div className="area-bg">
          <Roadmap />
          {/* <WhitePaper /> */}
        </div>

        {/* <TeamOne /> */}
        <ContactOne/>
      </main>
    </LayoutOne>
  );
};

export default Home;
