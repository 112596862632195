import React from "react";
import img01 from "../../assets/img/images/h2_about_img.png";
// import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container custom-container-four">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                <span>Nelcosoft</span>  sp. z o.o.
                </h2>
              </div>
              <p>
              
                A pioneering software development 
                company based in Poland. With over a decade of experience since 2007, 
                we have been dedicated to creating innovative software products. 
                Our current focus is on developing a revolutionary social business 
                networking platform that harnesses the potential of blockchain technology. 
                By providing a secure, transparent, and decentralized ecosystem, 
                we aim to empower businesses and individuals to connect, collaborate, 
                and thrive together. Join us in shaping the future of networking.
              </p>
              {/* <Link to="/" className="btn">
                Purchase Tokens
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
